import { default as __nuxt_component_0 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Mobile.vue";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/Common/EmptyList.vue";
import { default as __nuxt_component_2 } from "/opt/build/repo/components/Common/Table/Body.vue";
import { default as __nuxt_component_3 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Header.vue";
import { default as __nuxt_component_4 } from "/opt/build/repo/components/Partials/Common/Subaccount/TradeHistory/Row.vue";
import { default as __nuxt_component_5 } from "/opt/build/repo/components/Common/Table/Wrapper.vue";
import { default as __nuxt_component_6 } from "/opt/build/repo/components/Modals/MobileTradeDetails.vue";
import { default as __nuxt_component_7 } from "/opt/build/repo/components/App/HocLoading.vue";
"use strict";
import { defineComponent as _defineComponent } from "vue";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "w-full h-full" };
const _hoisted_2 = {
  key: 0,
  class: "table"
};
import { Status } from "@injectivelabs/utils";
import { Modal } from "@/types";
export default /* @__PURE__ */ _defineComponent({
  __name: "TradeHistory",
  props: {
    status: {
      type: Object,
      default: () => new Status()
    }
  },
  setup(__props) {
    const modalStore = useModalStore();
    const derivativeStore = useDerivativeStore();
    const tradeDetails = ref(void 0);
    const trades = computed(() => derivativeStore.subaccountTrades);
    function handleShowTradeDetails(value) {
      tradeDetails.value = value;
      modalStore.openModal({ type: Modal.MobileTradeDetails });
    }
    return (_ctx, _cache) => {
      const _component_PartialsCommonSubaccountTradeHistoryMobile = __nuxt_component_0;
      const _component_CommonEmptyList = __nuxt_component_1;
      const _component_CommonTableBody = __nuxt_component_2;
      const _component_PartialsCommonSubaccountTradeHistoryHeader = __nuxt_component_3;
      const _component_PartialsCommonSubaccountTradeHistoryRow = __nuxt_component_4;
      const _component_CommonTableWrapper = __nuxt_component_5;
      const _component_ModalsMobileTradeDetails = __nuxt_component_6;
      const _component_AppHocLoading = __nuxt_component_7;
      return _openBlock(), _createBlock(_component_AppHocLoading, {
        status: __props.status,
        "loader-class": __props.status.isLoading() ? "relative" : ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_CommonTableBody, {
              "show-empty": _unref(trades).length === 0,
              class: "sm:hidden mt-3 max-h-lg overflow-y-auto"
            }, {
              empty: _withCtx(() => [
                _createVNode(_component_CommonEmptyList, {
                  message: _ctx.$t("trade.emptyTrades"),
                  class: "pb-4 grow bg-gray-900"
                }, null, 8, ["message"])
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trades), (trade, index) => {
                  return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTradeHistoryMobile, {
                    key: `mobile-derivative-trade-${index}`,
                    class: "col-span-1",
                    trade,
                    onShowTradeDetails: handleShowTradeDetails
                  }, null, 8, ["trade"]);
                }), 128))
              ]),
              _: 1
            }, 8, ["show-empty"]),
            _createVNode(_component_CommonTableWrapper, {
              "break-md": "",
              class: "hidden sm:block"
            }, {
              default: _withCtx(() => [
                _unref(trades).length > 0 ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                  _createVNode(_component_PartialsCommonSubaccountTradeHistoryHeader),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(trades), (trade, index) => {
                      return _openBlock(), _createBlock(_component_PartialsCommonSubaccountTradeHistoryRow, {
                        key: `trade-${index}`,
                        trade
                      }, null, 8, ["trade"]);
                    }), 128))
                  ])
                ])) : (_openBlock(), _createBlock(_component_CommonEmptyList, {
                  key: 1,
                  message: _ctx.$t("trade.emptyTrades"),
                  "data-cy": "universal-table-nothing-found",
                  class: "pb-4 grow"
                }, null, 8, ["message"]))
              ]),
              _: 1
            }),
            _createVNode(_component_ModalsMobileTradeDetails, { trade: _unref(tradeDetails) }, null, 8, ["trade"])
          ])
        ]),
        _: 1
      }, 8, ["status", "loader-class"]);
    };
  }
});
